import {
  Avatar,
  Box,
  Button,
  Card,
  LinearProgress,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { OffecesCard } from "components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "../axiosConfig";

interface UserData {
  Address: string;
  admin_email: string;
  created_at: string;
  admin_username: string;
  id: number;
  Name: string;
  ID: number;
}
interface ReportData {
  total_lawyers: any;
  total_offices: number;
  total_users: number;
  total_requests: number;
}
const Inventory = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserData[]>([]);
  const [report, setReport] = useState<ReportData>();
  const [officeReport, setOfficeReport] = useState<ReportData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/superadmin/stats/all", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setReport(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // Handle office-specific report fetching
  const handleReportClick = async (officeId: number): Promise<any> => {
    console.log("🚀 ~ handleReportClick ~ officeId:", officeId);
    try {
      const response = await axios.get(`/superadmin/stats/office/${officeId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setOfficeReport(response.data);
      return response.data; // Return the data so it can be used immediately.
    } catch (error) {
      console.error("Error fetching office report:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/superadmin/all_offices", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (users.length > 0) {
      handleReportClick(users[0]?.ID);
    }
  }, [users]);
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };
  const data = {
    headerCards: [
      {
        title: "العملاء",
        count: report?.total_users,
        icon: "/user-icon4.svg",
        to: "/vendors",
      },
      {
        title: "المحامين",
        count: report?.total_lawyers,
        icon: "/user-icon5.svg",
        to: "/vendors",
      },
      {
        title: "المكاتب",
        count: report?.total_offices,
        icon: "/hugeicons_office.svg",
        to: "/clients",
      },
    ],
    progressBars: [
      {
        title: "عدد المحامين التابعين للمكتب",
        count: officeReport?.total_lawyers,
        progress: 0.3,
        to: "/category",
      },
      {
        title: "عدد العملاء التابعين للمكتب",
        count: officeReport?.total_users,
        progress: 0.6,
        to: "/category",
      },
      {
        title: "عدد الطلبات التي يستقبلها المكتب",
        count: officeReport?.total_requests,
        progress: 0.9,
        to: "/category",
      },
    ],
    tableData: Array(10).fill({ office: "مكتب الثقة", date: "01-05-2024" }),
  };
  const filteredAgents = users.filter((agent) =>
    agent?.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const paginatedAgents = filteredAgents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  // Custom styled PaginationItem for the selected page
  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#2E6CB6", // Background color for the selected page
      color: "#FFFFFF", // Text color for the selected page
      borderRadius: 4,
    },
  }));
  const formatArabicDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("ar-EG", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };
  const handlePrint = async (user: UserData) => {
    const reportData = await handleReportClick(user.ID);
    if (!reportData) return; // Handle error if reportData is null

    // Create a new window for the report
    const printWindow = window.open("", "_blank");
    if (!printWindow) return;

    // Generate the report HTML with a clean, professional design
    const reportHTML = `
        <!DOCTYPE html>
        <html dir="rtl" lang="ar">
        <head>
          <meta charset="UTF-8">
          <title>تقرير المكتب - ${user.Name}</title>
          <style>
            @media print {
              @page { size: A4; margin: 2cm; }
            }
            body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              color: #333;
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
            }
            .header {
              text-align: center;
              padding: 20px 0;
              border-bottom: 2px solid #2E6CB6;
              margin-bottom: 30px;
            }
            .logo {
              width: 100px;
              height: auto;
              margin-bottom: 15px;
            }
            .report-title {
              font-size: 24px;
              color: #2E6CB6;
              margin-bottom: 10px;
            }
            .info-section {
              margin-bottom: 25px;
            }
            .info-title {
              font-size: 18px;
              color: #2E6CB6;
              margin-bottom: 10px;
            }
            .info-grid {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 15px;
            }
            .info-item {
              padding: 10px;
              background-color: #f5f5f5;
              border-radius: 5px;
            }
            .info-label {
              font-weight: bold;
              margin-bottom: 5px;
            }
            .date {
              text-align: left;
              color: #666;
              font-size: 14px;
            }
            @media print {
              .no-print {
                display: none;
              }
            }
          </style>
        </head>
        <body>
          <div class="header">
            <img src="/lawfirm-logo.svg" alt="شعار المكتب" class="logo">
            <h1 class="report-title">تقرير المكتب</h1>
          </div>
          
          <div class="info-section">
            <h2 class="info-title">معلومات المكتب</h2>
            <div class="info-grid">
              <div class="info-item">
                <div class="info-label">اسم المكتب:</div>
                <div>${user.Name}</div>
              </div>
              <div class="info-item">
                <div class="info-label">البريد الإلكتروني:</div>
                <div>${user.admin_email}</div>
              </div>
              <div class="info-item">
                <div class="info-label">العنوان:</div>
                <div>${user.Address}</div>
              </div>
              <div class="info-item">
                <div class="info-label">تاريخ الإنشاء:</div>
                <div>${new Intl.DateTimeFormat("ar-EG", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(user.created_at))}</div>
              </div>
            </div>
          </div>

          <div class="info-section">
            <h2 class="info-title">إحصائيات المكتب</h2>
            <div class="info-grid">
              <div class="info-item">
                <div class="info-label">عدد المحامين:</div>
                <div>${reportData?.total_lawyers || 0}</div>
              </div>
              <div class="info-item">
                <div class="info-label">عدد العملاء:</div>
                <div>${reportData?.total_users || 0}</div>
              </div>
              <div class="info-item">
                <div class="info-label">عدد الطلبات:</div>
                <div>${reportData?.total_requests || 0}</div>
              </div>
            </div>
          </div>

          <div class="date">
            تاريخ الطباعة: ${new Intl.DateTimeFormat("ar-EG", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }).format(new Date())}
          </div>

          <button class="no-print" onclick="window.print()" style="
            position: fixed;
            bottom: 20px;
            right: 20px;
            padding: 10px 20px;
            background-color: #2E6CB6;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          ">طباعة التقرير</button>
        </body>
        </html>
      `;

    printWindow.document.write(reportHTML);
    printWindow.document.close();
  };
  return (
    <Box>
      <Box mt="20px" sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
        {data.headerCards.map((card, index) => (
          <Card
            key={index}
            component={Link}
            to={card.to}
            sx={{
              maxWidth: "330px",
              width: "300px", // Ensure the width is set
              height: "auto", // You can also set a specific height if needed
              padding: "20px", // Adjust the padding if needed
              "&:hover": {
                boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
              },
              cursor: "pointer",
            }}
            elevation={4}
          >
            <OffecesCard
              prop={card.title}
              icroundNotifications={card.icon}
              prop1={card?.count}
            />
          </Card>
        ))}
      </Box>
      <Box display="flex" p={2} height="100vh">
        <Box width="50%" p={2} mt="20px">
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>

                  <TableCell>المكتب</TableCell>
                  <TableCell>تاريخ الإنشاء</TableCell>
                  <TableCell></TableCell>

                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAgents.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Avatar src={"/lawfirm-logo.svg"} alt={row.Name} />
                    </TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>
                      <td>{formatArabicDate(row.created_at)}</td>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="text"
                        color="info"
                        onClick={() => handleReportClick(row.ID)}
                      >
                        التقارير
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => handlePrint(row)}
                      >
                        طباعة
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(filteredAgents.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="standard"
              renderItem={(item) => (
                <CustomPaginationItem
                  components={{
                    previous: () => (
                      <img
                        src="/keyboard_double_arrow_right.svg"
                        alt="Previous"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                    next: () => (
                      <img
                        src="/keyboard_double_arrow_left.svg"
                        alt="Next"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  }}
                  {...item}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          width="50%"
          p={5}
          mt="20px"
          display="flex"
          flexDirection={"column"}
        >
          {data.progressBars.map((bar, index) => (
            <Card
              key={index}
              component={Link}
              to={bar.to}
              sx={{
                padding: "20px", // Adjust the padding if needed
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                margin: "10px",
                cursor: "pointer",
              }}
              elevation={4}
            >
              <Typography variant="h6">{bar.title}</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="h4" sx={{ minWidth: "50px" }}>
                  {bar.count}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={bar.progress * 100}
                  sx={{ flex: 1, ml: 2, height: "10px", borderRadius: "5px" }}
                />
              </Stack>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Inventory;
