import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../../axiosConfig";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";

// Components
import { AddButton, CustomButton } from "components";
import { ClientProps } from "interfaces/agent";
import SearchBar from "./SearchBar";

const Clients = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState<ClientProps>();
  const [data, setData] = useState<ClientProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("admin/get_users");
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Filter for search
  const filteredUsers = data.filter((user) =>
    user.username?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Only clients
  const onlyClients = filteredUsers.filter((user) => user.type === "client");

  // Paginate
  const paginatedClients = onlyClients.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Modal handlers
  const handleOpen = (agent: ClientProps) => {
    setAgentToDelete(agent);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    if (!agentToDelete) return;
    try {
      const response = await axios.delete(
        `admin/delete_user/${agentToDelete.id}`
      );
      if (response.status === 200) {
        // Remove deleted client from state
        setData((prev) => prev.filter((u) => u.id !== agentToDelete.id));
        console.log("Deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting:", error);
    } finally {
      handleClose();
    }
  };

  // Handle search changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    // Optionally reset to page 1 on new search
    setCurrentPage(1);
  };

  return (
    <Box>
      {/* Delete Confirmation Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">
            {t("Are you sure you want to delete this agent?")}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CustomButton
              type="submit"
              title={t("confirm")}
              backgroundColor="#C84630"
              color="#fcfcfc"
              handleClick={handleDelete}
            />
            <Box sx={{ mx: 2 }} />
            <CustomButton
              type="button"
              title={t("cancel")}
              backgroundColor="#ccc"
              color="#000"
              handleClick={handleClose}
            />
          </Box>
        </Box>
      </Modal>

      {/* Title */}
      <Typography
        fontSize={25}
        fontWeight={700}
        color="#11142d"
        fontFamily={"Wahran"}
      >
        {t("clients")}
      </Typography>

      {/* Search & Add Buttons */}
      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        {/* NEW SearchBar COMPONENT */}
        <SearchBar
          placeholder={t("search_placeholder")}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
        />

        <AddButton
          title={t("add_new_vendor")}
          handleClick={() => navigate("/clients/create")}
          backgroundColor="#2E6CB6"
          color="#fcfcfc"
          icon={
            <img
              style={{ width: 24, height: 24 }}
              alt=""
              src={"/Group 239.svg"}
            />
          }
        />
      </Box>

      {/* Table of Clients */}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow style={{ background: "rgba(220, 236, 255, 0.30)" }}>
              <TableCell />
              <TableCell>{t("username")}</TableCell>
              <TableCell>{t("user_type")}</TableCell>
              <TableCell>{t("email")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedClients.map((agent) => (
              <TableRow key={agent.id}>
                <TableCell>
                  <img
                    style={{ width: 30, height: 30 }}
                    alt="User icon"
                    src={"/ssasdsada.svg"}
                  />
                </TableCell>
                <TableCell>{agent.username}</TableCell>
                <TableCell>
                  <img
                    style={{ width: 50, height: 50 }}
                    alt="Client icon"
                    src={"/client.svg"}
                  />
                </TableCell>
                <TableCell>{agent.email}</TableCell>
                <TableCell>
                  <Link
                    to="/clients/edit"
                    state={{ agent }}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton>
                      <EditIcon style={{ color: "#8DA7C8" }} />
                    </IconButton>
                  </Link>

                  <IconButton onClick={() => handleOpen(agent)}>
                    <DeleteIcon style={{ color: "#8DA7C8" }} />
                  </IconButton>

                  <Link
                    to="/clients/show"
                    state={{ agent }}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton>
                      <InfoIcon style={{ color: "#8DA7C8" }} />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}

            {/* If no results */}
            {paginatedClients.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {isLoading ? t("loading") : t("no_results_found")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(onlyClients.length / itemsPerPage)}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          color="standard"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              components={{
                previous: () => (
                  <img
                    src="/keyboard_double_arrow_right.svg"
                    alt="Previous"
                    style={{ width: 24, height: 24 }}
                  />
                ),
                next: () => (
                  <img
                    src="/keyboard_double_arrow_left.svg"
                    alt="Next"
                    style={{ width: 24, height: 24 }}
                  />
                ),
              }}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#2E6CB6",
                  color: "#FFFFFF",
                  borderRadius: 4,
                },
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default Clients;
