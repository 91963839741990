import { Icon } from "@iconify/react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { LegacyAuthProvider as AuthProvider, Refine } from "@refinedev/core";
import {
  ErrorComponent,
  notificationProvider,
  ReadyPage,
  RefineSnackbarProvider,
} from "@refinedev/mui";

import routerProvider from "@refinedev/react-router-v6/legacy";
import { Header, Layout, Sider, Title } from "components/layout";
import { ColorModeContextProvider } from "contexts";
import {
  CreateProperty,
  Login,
  CreateClient,
  ClientProfile,
  Vendor,
  VendorProfile,
  Accounting,
  Inventory,
  Item,
  office,
} from "pages";
import adminMain from "pages/main/admin-main";
import Clients from "pages/clients/clients";
import CreateClients from "pages/clients/CreateClients";
import Lawyers from "pages/lawyers/lawyers";
import Requests from "pages/requests/requests";
import RequestDetails from "pages/requests/requestDetails";
import CreateRequest from "pages/requests/CreateRequest";
import Cases from "pages/cases/cases";
import CaseDetails from "pages/cases/caseDetails";
import Notifications from "pages/notifications/notifications";
import ReportsDetails from "pages/reports/ReportsDetails";
import { useTranslation } from "react-i18next";
import axios from "./axiosConfig";
import legalLibrary from "pages/legal/legalLibrary";
import EditUser from "pages/clients/edit-user";
import reports from "pages/reports/reports";
import ConditionalEditComponent from "pages/requests/ConditionalEditComponent";
import CreateOffice from "pages/create-office";
import EditOffice from "pages/EditOffice";
import { create } from "domain";
import AddAdmin from "pages/AddAdmin";

function App() {
  const authProvider: AuthProvider = {
    login: async ({ email, password }: { email: string; password: string }) => {
      console.log("🚀 ~ login: ~ data:");

      try {
        localStorage.clear();

        const response = await axios.post("/auth/login", {
          email,
          password,
        });

        const data = response.data;
        console.log("🚀 ~ login: ~ data:", data);

        if (response.status === 200) {
          // Corrected the role validation logic
          if (data.user.role !== "admin" && data.user.role !== "superadmin") {
            return Promise.reject("Access Denied: Insufficient Permissions");
          } else {
            // Save the user data in localStorage
            localStorage.setItem("user", JSON.stringify(data.user));

            // Redirect to the home page after successful login
            window.location.href = "/";
            return Promise.resolve();
          }
        } else {
          console.error("Login failed:", data.message);
          return Promise.reject(data.message);
        }
      } catch (error) {
        console.error("Error logging in:", error);
        return Promise.reject(error);
      }
    },

    logout: () => {
      if (typeof window !== "undefined") {
        localStorage.removeItem("user"); // Remove user data from localStorage
        window.location.href = "/goodbye";
      }
      return Promise.resolve();
    },

    checkError: () => Promise.resolve(),

    checkAuth: async () => {
      const user = localStorage.getItem("user");
      return user ? Promise.resolve() : Promise.reject();
    },

    getPermissions: async () => null,

    getUserIdentity: async () => {
      const user = localStorage.getItem("user");
      return user ? Promise.resolve(JSON.parse(user)) : null;
    },
  };

  const { t } = useTranslation();
  const user = localStorage.getItem("user");
  let userRole;

  if (user) {
    try {
      const parsedUser = JSON.parse(user); // Parse the string into an object
      userRole = parsedUser.role; // Access the role property
    } catch (error) {
      console.error("Error parsing user from localStorage:", error);
    }
  }

  // Define resources based on user role
  const resources =
    userRole === "superadmin"
      ? [
          {
            name: "superadmin/get_users",
            options: { label: t("Main") },
            list: Accounting,
            icon: <Icon icon="majesticons:home-line" width="23" height="23" />,
          },
          {
            name: "superadmin/add_admin",
            create: AddAdmin,
          },
          {
            name: "vendors",
            options: { label: t("Users") },
            list: Vendor,
            show: VendorProfile,
            edit: CreateProperty,
            create: CreateClient,
            icon: <Icon icon="mdi:users" width="23" height="23" />,
          },
          {
            name: "clients",
            options: { label: t("Offices") },
            list: office,
            edit: EditOffice,
            show: ClientProfile,
            create: CreateOffice,
            icon: <Icon icon="hugeicons:office" width="23" height="23" />,
          },
          {
            name: "Items",
            options: { label: t("Notifications") },
            icon: <Icon icon="ic:round-notifications" width="23" height="23" />,
            list: Item,
          },
          {
            name: "category",
            options: { label: t("Reports") },
            list: Inventory,
            icon: <Icon icon="carbon:report" width="23" height="23" />,
          },
        ]
      : [
          {
            name: "/",
            options: { label: t("Main") },
            list: adminMain,
            show: legalLibrary,
            edit: EditUser,
            icon: <Icon icon="majesticons:home-line" width="23" height="23" />,
          },
          {
            name: "Clients",
            options: { label: t("Clients") },
            list: Clients,
            show: VendorProfile,
            edit: EditUser,
            create: CreateClients,
            icon: <Icon icon="mdi:users" width="23" height="23" />,
          },
          {
            name: "Lawyers",
            options: { label: t("lawyers") },
            list: Lawyers,
            edit: EditUser,
            show: VendorProfile,
            create: CreateClients,
            icon: <Icon icon="raphael:employee" width="23" height="23" />,
          },
          {
            name: "Requests",
            options: { label: t("Requests") },
            list: Requests,
            edit: ConditionalEditComponent,
            show: RequestDetails,
            create: CreateRequest,
            route: "/Requests/edit/:type",
            icon: <Icon icon="clarity:list-solid" width="23" height="23" />,
          },
          {
            name: "Cases",
            options: { label: t("Cases") },
            list: Cases,
            edit: CreateProperty,
            show: CaseDetails,
            create: CreateRequest,
            icon: <Icon icon="iconoir:suitcase" width="23" height="23" />,
          },

          {
            name: "Notifications",
            options: { label: t("Notifications") },
            list: Notifications,
            edit: CreateProperty,
            // show: AppointmentsDetails,
            // create: CreateAppointment,
            icon: <Icon icon="ic:round-notifications" width="23" height="23" />,
          },

          {
            name: "Reports",
            options: { label: t("Reports") },
            // list: Reports,
            list: reports,
            edit: CreateProperty,
            show: ReportsDetails,
            // create: CreateAppointment,
            icon: (
              <Icon
                icon="material-symbols:lab-profile-outline"
                width="23"
                height="23"
              />
            ),
          },
        ];

  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          // dataProvider={dataProvider("http://153.92.223.86:5001")}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          resources={resources}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
          }}
          legacyRouterProvider={routerProvider}
          legacyAuthProvider={authProvider}
          LoginPage={Login}
          DashboardPage={userRole === "superadmin" ? Accounting : adminMain}
        />
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
}
// oldGold
export default App;
