import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, LinearProgress, Card } from "@mui/material";
import { Link } from "react-router-dom";
import { OffecesCard } from "components";
import { useTranslation } from "react-i18next";
import axios from "../../axiosConfig";

interface UserData {
  Address: string;
  admin_email: string;
  created_at: string;
  admin_username: string;
  id: number;
  Name: string;
  ID: number;
}
interface ReportData {
  total_lawyers: any;
  total_offices: number;
  total_users: number;
  total_requests: number;
  total_cases: number;
}
const Reports = () => {
  const [report, setReport] = useState<ReportData>();
  useEffect(() => {
    axios
      .get("admin/reports", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        setReport(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const data = {
    headerCards: [
      {
        title: "العملاء",
        count: report?.total_users,
        icon: "/user-icon4.svg",
      },
      {
        title: "المحامين",
        count: report?.total_lawyers,
        icon: "/user-icon5.svg",
      },
      {
        title: "الطلبات",
        count: report?.total_requests,
        icon: "/clarity_list-solid.svg",
      },
    ],
    progressBars: [
      {
        title: "عدد المحامين التابعين للمكتب",
        count: report?.total_lawyers,
        progress: 0.3,
      },
      {
        title: "عدد العملاء التابعين للمكتب",
        count: report?.total_users,
        progress: 0.6,
      },
      {
        title: "عدد القضايا التي يستقبلها المكتب",
        count: report?.total_cases,
        progress: 0.9,
      },
    ],
    tableData: Array(10).fill({ office: "مكتب الثقة", date: "01-05-2024" }),
  };

  return (
    <Box>
      <Box mt="20px" sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
        {data.headerCards.map((card, index) => (
          <Card
            key={index}
            component={Link}
            to="" // Add the 'to' property with the desired path
            sx={{
              maxWidth: "330px",
              width: "300px", // Ensure the width is set
              height: "auto", // You can also set a specific height if needed
              padding: "20px", // Adjust the padding if needed
              "&:hover": {
                boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
              },
              cursor: "pointer",
            }}
            elevation={4}
          >
            <OffecesCard
              prop={card.title}
              icroundNotifications={card.icon}
              prop1={card?.count}
            />
          </Card>
        ))}
      </Box>
      <Box display="flex" p={2} height="100vh">
        <Box
          width="50%"
          p={5}
          mt="20px"
          display="flex"
          flexDirection={"column"}
        >
          {data.progressBars.map((bar, index) => (
            <Card
              key={index}
              component={Link}
              to=""
              sx={{
                padding: "20px", // Adjust the padding if needed
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                margin: "10px",
                cursor: "pointer",
              }}
              elevation={4}
            >
              <Typography variant="h6">{bar.title}</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="h4" sx={{ minWidth: "50px" }}>
                  {bar.count}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={bar.progress * 100}
                  sx={{ flex: 1, ml: 2, height: "10px", borderRadius: "5px" }}
                />
              </Stack>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;
