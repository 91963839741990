import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputBase,
  Modal,
  Paper,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axiosInstance from "axiosConfig";
import React, { useEffect, useMemo, useRef, useState } from "react";

const LegalLibrary = () => {
  interface Document {
    id: number | null;
    name: string;
    file_path: string;
    filename?: string;
  }

  const [documents, setDocuments] = useState<Document[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteDocId, setDeleteDocId] = useState<number | null>(null);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [messageModalText, setMessageModalText] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalDocuments, setTotalDocuments] = useState(0);

  // ---- NEW STATES FOR RENAME DIALOG ----
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [renameDocId, setRenameDocId] = useState<number | null>(null);
  const [newDocName, setNewDocName] = useState("");

  const searchInputRef = useRef<HTMLInputElement>(null);

  const normalizeDocument = (doc: any) => ({
    id: doc?.id || null,
    name: doc?.filename || "Unnamed Document",
    file_path: doc?.file_path || "",
  });

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/admin/legal-documents");
        console.log("🚀 ~ fetchDocuments ~ response:", response);

        const fetchedDocuments = response.data.documents.map(normalizeDocument);
        setDocuments(fetchedDocuments);
        setTotalDocuments(response.data.total || 0);
      } catch (error) {
        console.error("Error fetching documents:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const filteredDocuments = useMemo(() => {
    return documents.filter(
      (doc) =>
        doc?.name?.includes(searchQuery) || doc?.filename?.includes(searchQuery)
    );
  }, [documents, searchQuery]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    } else {
      console.error("No file selected");
    }
  };

  const handleAddDocument = async () => {
    if (!selectedFile) {
      setMessageModalText("Please select a file before confirming.");
      setOpenMessageModal(true);
      return;
    }

    try {
      setUploading(true);

      const formData = new FormData();
      formData.append("document", selectedFile);
      formData.append("filename", selectedFile.name);
      formData.append("document_type", "legal");

      const response = await axiosInstance.post(
        `/admin/legal-documents`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      console.log(
        "🚀 ~ handleAddDocument ~ response.data.document:",
        response.data.document
      );
      setDocuments((prev) => [...prev, response.data.document]);

      setOpenModal(false);
      setSelectedFile(null);
    } catch (error: any) {
      console.log("🚀 ~ handleAddDocument ~ error:", error);
      setMessageModalText("حدث خطاء فى رفع الملف برجاء التحميل فى وقت اخر.");
      setOpenMessageModal(true);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    if (!deleteDocId) return;

    try {
      await axiosInstance.delete(`/admin/delete/legal-document/${deleteDocId}`);
      setDocuments(documents.filter((doc) => doc.id !== deleteDocId));
      setTotalDocuments((prev) => prev - 1);
      setMessageModalText("تم حذف الملف بنجاح .");
      setOpenMessageModal(true);
    } catch (error) {
      setMessageModalText("Failed to delete the document. Please try again.");
      setOpenMessageModal(true);
    } finally {
      setOpenDeleteModal(false);
      setDeleteDocId(null);
    }
  };

  // ---- NEW: OPEN THE RENAME MODAL ----
  const handleOpenRenameModal = (doc: Document) => {
    setOpenRenameModal(true);
    setRenameDocId(doc.id);
    setNewDocName(doc.name); // If you want to show the current name
  };

  // ---- NEW: CLOSE THE RENAME MODAL ----
  const handleCloseRenameModal = () => {
    setOpenRenameModal(false);
    setRenameDocId(null);
    setNewDocName("");
  };

  // ---- NEW: SEND PATCH REQUEST TO RENAME THE DOCUMENT ----
  const handleRename = async () => {
    if (!renameDocId || !newDocName.trim()) return;

    try {
      // Adjust the body shape to match your backend requirement
      await axiosInstance.patch("/admin/legal-documents", {
        doc_id: renameDocId,
        filename: newDocName.trim(),
      });

      // Update the document in local state
      setDocuments((prev) =>
        prev.map((doc) =>
          doc.id === renameDocId ? { ...doc, name: newDocName.trim() } : doc
        )
      );

      setMessageModalText("تم تغيير اسم المستند بنجاح.");
      setOpenMessageModal(true);
    } catch (error) {
      console.error("Error renaming document:", error);
      setMessageModalText("حدث خطأ أثناء تغيير اسم المستند.");
      setOpenMessageModal(true);
    } finally {
      handleCloseRenameModal();
    }
  };

  const handleDownload = (path: string) => {
    const fileUri = `https://files.elavvocato.com/${path}`;
    window.open(fileUri, "_blank");
  };

  const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E7FF",
    borderRadius: "8px",
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#F8FAFF",
    width: "50%",
  }));

  const CustomInputBase = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    "& input::placeholder": {
      textAlign: "right",
    },
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#D1B76C",
    color: "#fff",
    gap: theme.spacing(1),
    padding: theme.spacing(1, 3),
    textTransform: "none",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#BFA25C",
    },
  }));

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography style={{ color: "#111923" }} variant="h5" fontWeight="700">
          المكتبة القانونية
        </Typography>
        <StyledButton
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setOpenModal(true)}
        >
          إضافة مستند
        </StyledButton>
      </Box>

      <Typography variant="subtitle1" mb={2}>
        عدد المستندات: {totalDocuments}
      </Typography>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <SearchBox>
          <IconButton sx={{ color: "#8DA7C8" }}>
            <SearchIcon />
          </IconButton>
          <CustomInputBase
            placeholder="البحث عن مستند..."
            value={searchQuery}
            onChange={handleSearchChange}
            inputRef={searchInputRef}
          />
        </SearchBox>
      </Box>

      <TableContainer component={Paper}>
        {loading ? (
          <Typography sx={{ p: 2, textAlign: "center" }}>
            جاري تحميل المستندات...
          </Typography>
        ) : filteredDocuments.length > 0 ? (
          filteredDocuments.map((doc) => (
            <Box
              key={doc.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              borderBottom="1px solid #ddd"
            >
              <Typography>{doc?.name}</Typography>
              <Box>
                <IconButton
                  sx={{ color: "#8DA7C8" }}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setDeleteDocId(doc.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  sx={{ color: "#8DA7C8" }}
                  onClick={() => doc.id !== null && handleOpenRenameModal(doc)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: "#8DA7C8" }}
                  onClick={() => handleDownload(doc.file_path)}
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={{ p: 2, textAlign: "center" }}>
            لا توجد مستندات لعرضها.
          </Typography>
        )}
      </TableContainer>

      {/* Add Document Modal */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography mb={2}>أضف مستند جديد</Typography>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            disabled={uploading}
            style={{ marginBottom: "16px" }}
          />
          <Box mt={2} display="flex" justifyContent="space-between">
            <StyledButton
              onClick={handleAddDocument}
              disabled={!selectedFile || uploading}
            >
              {uploading ? "جاري التحميل..." : "إضافة"}
            </StyledButton>
            <StyledButton onClick={() => setOpenModal(false)}>
              إلغاء
            </StyledButton>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">حذف المستند</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل أنت متأكد أنك تريد حذف هذا المستند؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>إلغاء</Button>
          <Button onClick={handleDelete} autoFocus>
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>

      {/* NEW: Rename Document Dialog */}
      <Dialog open={openRenameModal} onClose={handleCloseRenameModal}>
        <DialogTitle>إعادة تسمية المستند</DialogTitle>
        <DialogContent>
          <DialogContentText>أدخل الاسم الجديد للمستند:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="الاسم الجديد"
            type="text"
            fullWidth
            variant="standard"
            value={newDocName}
            onChange={(e) => setNewDocName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRenameModal}>إلغاء</Button>
          <Button onClick={handleRename} disabled={!newDocName.trim()}>
            حفظ
          </Button>
        </DialogActions>
      </Dialog>

      {/* Message Modal */}
      <Dialog
        open={openMessageModal}
        onClose={() => setOpenMessageModal(false)}
      >
        <DialogContent>
          <DialogContentText>{messageModalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMessageModal(false)}>إغلاق</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LegalLibrary;
