import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Checkbox,
  styled,
  InputBase,
  PaginationItem,
  Button,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { CustomButton } from "components";
import axios from "../../axiosConfig";
import SearchBar from "pages/clients/SearchBar";

const dummyNotifications = [
  {
    title: "عنوان الإشعار",
    content:
      "رجى العلم أننا قد قدمنا طلبًا لرفع قضية نيابة عنكم في المحكمة المختصة. سنواصل متابعة القضية وتقديم التحديثات اللازمة لكم. إذا كان لديكم أي استفسار أو تحتاجون إلى مزيد من المعلومات، فلا تترددوا في الاتصال بنا.",
    date: "29 مايو 2024 10:08 ص",
  },
  {
    title: "عنوان الإشعار",
    content:
      "رجى العلم أننا قد قدمنا طلبًا لرفع قضية نيابة عنكم في المحكمة المختصة. سنواصل متابعة القضية وتقديم التحديثات اللازمة لكم. إذا كان لديكم أي استفسار أو تحتاجون إلى مزيد من المعلومات، فلا تترددوا في الاتصال بنا.",
    date: "29 مايو 2024 10:08 ص",
  },
  // Add more dummy data as needed
];
interface UserData {
  id: number;
  Name: string; // Change 'Name' to 'name' if data uses lowercase
  type: string;
  admin_username: string;
  username: string;
  date: string;
  notification_type: string;
  content: string;
  created_at: string;
  message: string;

  // Add other relevant fields as needed
}
const Notifications: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [notification, setNotification] = useState<UserData[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState(""); // State for the message
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(true); // To track success or error
  const { t } = useTranslation();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const handleSortByType = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/admin/get_users", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseNotifications = await axios.get(
          "/admin/get_notifications",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setUsers(response.data);
        setNotification(responseNotifications.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const formatArabicDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("ar-EG", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };
  const itemsPerPage = 10; // Number of items per page
  const allItems = users ?? [];

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allItems.map((item) => item.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectItem = (id: number) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    // Optionally reset to page 1 on new search
    setCurrentPage(1);
  };

  const filteredAgents = allItems
    .filter(
      (user) =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.type.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a.type.localeCompare(b.type, "ar");
      } else {
        return b.type.localeCompare(a.type, "ar");
      }
    });

  const paginatedAgents = filteredAgents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };


  // Custom styled PaginationItem for the selected page
  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#2E6CB6", // Background color for the selected page
      color: "#FFFFFF", // Text color for the selected page
      borderRadius: 4,
    },
  }));

  const createNotification = async () => {
    if (selectedItems.length === 0) {
      return;
    }
  
    const selectedUser = users.find((user) => user.id === selectedItems[0]);
    const recipientType = selectedUser?.type === "client" ? "user" : "lawyer";
  
    try {
      const data = await axios.post("/admin/create_notifications", {
        message,
        recipient_id: selectedItems,
        recipient_type: recipientType,
      });
      console.log("Notification created:", data);
      setModalMessage(t("NotificationAddedSuccessfully"));
      setIsSuccess(true);
      setModalOpen(true);
    } catch (error) {
      console.log("Error creating notification:", error);
      setModalMessage(t("errorCreatingNotification"));
      setIsSuccess(false);
      setModalOpen(true);
    }
  };
  

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Box display="flex" p={2} height="100vh" bgcolor={"#fff"}>
      {/* Items Section */}
      <Box width="50%" p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={25} fontWeight={700} color="#11142d">
            {t("notifications")}
          </Typography>
        </Box>

        <SearchBar
          placeholder={t("search_placeholder")}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
        />
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow style={{ background: "rgba(220, 236, 255, 0.30)" }}>
                <TableCell></TableCell>
                <TableCell>الاسم </TableCell>
                <TableCell
                  onClick={handleSortByType}
                  style={{ cursor: "pointer" }}
                >
                  نوع المستخدم
                  {sortOrder === "asc" ? " ▲" : " ▼"}
                </TableCell>

                <TableCell>
                  <Checkbox
                    color="info"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAgents.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>
                    {category.type === "client" ? (
                      <img
                        className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                        alt=""
                        src={"/ssasdsada.svg"}
                      />
                    ) : (
                      <img
                        className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                        alt=""
                        src={"/lawyer-icon.svg"}
                      />
                    )}
                  </TableCell>
                  <TableCell>{category.username}</TableCell>
                  <TableCell>
                    {category.type === "client" ? (
                      <img
                        className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                        alt=""
                        src={"/client.svg"}
                      />
                    ) : (
                      <img
                        className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                        alt=""
                        src={"/lawyer.svg"}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      color="info"
                      checked={selectedItems.includes(category.id)}
                      onChange={() => handleSelectItem(category.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(filteredAgents.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="standard"
            renderItem={(item) => (
              <CustomPaginationItem
                components={{
                  previous: () => (
                    <img
                      src="/keyboard_double_arrow_right.svg"
                      alt="Previous"
                      style={{ width: "24px", height: "24px" }}
                    />
                  ),
                  next: () => (
                    <img
                      src="/keyboard_double_arrow_left.svg"
                      alt="Next"
                      style={{ width: "24px", height: "24px" }}
                    />
                  ),
                }}
                {...item}
              />
            )}
          />
        </Box>
      </Box>

      {/* Notifications Section */}
      <Box width="50%" borderRight="1px solid #e0e0e0" p={2}>
        {/* Scrollable Container */}
        <Box
          sx={{
            maxHeight: "400px", // Set a fixed height for the scroll area
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          {notification.map((notification, index) => (
            <Box
              key={index}
              p={2}
              mb={2}
              border="1px solid #e0e0e0"
              borderRadius="8px"
              bgcolor={"rgba(141, 167, 200, 0.3)"}
            >
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body1" fontWeight={600} color={"#255186"}>
                  {notification.notification_type}
                </Typography>
                <Typography variant="caption" display="block">
                  {formatArabicDate(notification.created_at)}
                </Typography>
              </Box>
              <Typography color={"#255186"} variant="body2">
                {notification.message}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Input Section */}
        <Box
          flexDirection={"row"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={2} // Add spacing to separate from notifications
        >
          <IconButton onClick={createNotification}>
            <img
              src="/send.svg"
              alt="Send"
              style={{ width: "40px", height: "40px" }}
            />
          </IconButton>
          <TextField
            fullWidth
            placeholder="اكتب هنا..."
            variant="outlined"
            size="small"
            multiline
            rows={2} // Adjust the number of rows as needed
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
      </Box>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <img
            src={isSuccess ? "/letsiconscheckfill.svg" : "/mdi--error-2.svg"}
            alt={isSuccess ? "Success" : "Error"}
            style={{ width: "59px", height: "59px" }}
          />
          <Typography
            id="success-modal-title"
            variant="h4"
            component="h2"
            m={5}
          >
            {modalMessage}
          </Typography>
          <CustomButton
            type="submit"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};
// oldGold
export default Notifications;
