import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { FormProps } from "interfaces/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../axiosConfig";
import CustomButton from "../common/CustomButton";
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    borderColor: "#D1D5DB",
    transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    "& fieldset": {
      borderColor: "#D1D5DB",
    },
    "&:hover fieldset": {
      borderColor: "#2E6CB6",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2E6CB6",
      boxShadow: `0 0 0 4px ${theme.palette.primary.light}33`,
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.9rem",
    color: "#6B7280",
  },
}));

const ClientForm = ({
  type,
  register,
  handleSubmit,
  agent,
  formLoading,
  onFinishHandler,
}: FormProps) => {
  const { t } = useTranslation();
  interface Office {
    ID: string;
    Name: string;
  }
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const [offices, setOffices] = useState<Office[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/superadmin/all_offices", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setOffices(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Box>
      <Typography
        fontSize={28}
        fontWeight={700}
        color="#1F2937"
        margin={2}
        textAlign="right"
        mb={2}
      >
        {type === "Create" ? t("add_new_vendor") : t("editclient")}
      </Typography>

      <Box
        mt={3}
        width={{
          xs: "100%",
          sm: "90%",
          md: "70%",
          lg: "60%",
          xl: "50%",
        }}
        borderRadius="20px"
        padding="24px"
        bgcolor="#ffffff"
        boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onFinishHandler)}
        >
          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            {/* Office Selection Dropdown */}
            {user.role === "superadmin" && (
              <StyledFormControl
                sx={{
                  direction: "ltr",
                }}
              >
                <FormHelperText sx={{ fontSize: "1rem", marginBottom: "8px" }}>
                  {t("selectOffice")}
                </FormHelperText>
                <Select
                  fullWidth
                  id="office"
                  defaultValue=""
                  variant="outlined"
                  {...register("office_id")} // Register with office_id
                >
                  {offices.map((office) => (
                    <MenuItem key={office?.ID} value={office?.ID}>
                      {office?.Name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            )}
          </Stack>
          {/* User Type and Gender in the same row */}
          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <FormHelperText sx={{ fontSize: "1rem", marginBottom: "8px" }}>
                {t("userType")}
              </FormHelperText>
              <Select
                fullWidth
                id="userType"
                defaultValue=""
                variant="outlined"
                {...register("user_type")}
              >
                <MenuItem value="user">{t("User")}</MenuItem>
                <MenuItem value="lawyer">{t("lawyer")}</MenuItem>
              </Select>
            </StyledFormControl>
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <FormHelperText sx={{ fontSize: "1rem", marginBottom: "8px" }}>
                {t("gender")}
              </FormHelperText>
              <Select
                fullWidth
                id="gender"
                defaultValue=""
                variant="outlined"
                {...register("gender")}
              >
                <MenuItem value="male">{t("male")}</MenuItem>
                <MenuItem value="female">{t("female")}</MenuItem>
              </Select>
            </StyledFormControl>
          </Stack>

          {/* Username and Email in the same row */}
          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <TextField
                required
                id="username"
                label={t("enterClientName")}
                variant="outlined"
                {...register("username")}
              />
            </StyledFormControl>
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <TextField
                required
                id="email"
                label={t("enterClientEmail")}
                variant="outlined"
                {...register("email")}
              />
            </StyledFormControl>
          </Stack>

          {/* Phone and ID Document in the same row */}
          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <TextField
                id="phone"
                label={t("enterClientPhone")}
                variant="outlined"
                {...register("phone")}
              />
            </StyledFormControl>
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <TextField
                id="id_document"
                label={t("idNumber")}
                variant="outlined"
                {...register("id_document")}
              />
            </StyledFormControl>
          </Stack>

          {/* Address */}
          <StyledFormControl
            sx={{
              direction: "ltr",
            }}
          >
            <TextField
              id="address"
              label={t("enterClientAddress")}
              variant="outlined"
              {...register("address")}
            />
          </StyledFormControl>

          {/* Password and Confirm Password */}
          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <TextField
                required
                id="password"
                label={t("password")}
                type="password"
                variant="outlined"
                {...register("password")}
              />
            </StyledFormControl>
            <StyledFormControl
              sx={{
                direction: "ltr",
              }}
            >
              <TextField
                required
                id="confirmPassword"
                label={t("confirmPassword")}
                type="password"
                variant="outlined"
                {...register("confirmPassword")}
              />
            </StyledFormControl>
          </Stack>

          <Box mt={2} textAlign="end">
            <CustomButton
              type="submit"
              title={formLoading ? t("submit") : t("submit")}
              backgroundColor="#2E6CB6"
              color="#ffffff"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ClientForm;
