import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Checkbox,
  styled,
  InputBase,
  PaginationItem,
  Modal,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomButton } from "components";
import axios from "../../axiosConfig";
import SearchBar from "pages/clients/SearchBar";

interface UserData {
  ID: number;
  Name: string;
  type: string;
  admin_username: string;
  date: string;
  title: string;
  content: string;
  CreatedAt: string;
  Message: string;
  // Add other relevant fields as needed
}


const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#2E6CB6",
    color: "#FFFFFF",
    borderRadius: 4,
  },
}));



// Memoized row component (optional)
const AgentRow = React.memo(
  ({
    agent,
    isSelected,
    onSelect,
  }: {
    agent: UserData;
    isSelected: boolean;
    onSelect: (id: number) => void;
  }) => (
    <TableRow key={agent.ID}>
      <TableCell>
        <Avatar src="/lawfirm-logo.svg" alt={agent.Name} />
      </TableCell>
      <TableCell>{agent.Name}</TableCell>
      <TableCell>{agent.admin_username}</TableCell>
      <TableCell>
        <Checkbox
          color="info"
          checked={isSelected}
          onChange={() => onSelect(agent.ID)}
        />
      </TableCell>
    </TableRow>
  )
);

const Item: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [notification, setNotification] = useState<UserData[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(true);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const { t } = useTranslation();

  // Fetch both data sets concurrently
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [officesResponse, notificationsResponse] = await Promise.all([
          axios.get("/superadmin/all_offices", {
            headers: { "Content-Type": "application/json" },
          }),
          axios.get("/superadmin/notifications", {
            headers: { "Content-Type": "application/json" },
          }),
        ]);
        setUsers(officesResponse.data);
        setNotification(notificationsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const itemsPerPage = 10;
  const allItems = useMemo(() => users ?? [], [users]);

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allItems.map((item) => item.ID));
    }
    setSelectAll((prev) => !prev);
  }, [allItems, selectAll]);

  const handleSelectItem = useCallback((id: number) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  }, []);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
      setCurrentPage(1);
    },
    []
  );

  const filteredAgents = useMemo(() => {
    return allItems.filter(
      (user) =>
        user.admin_username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [allItems, searchQuery]);

  const paginatedAgents = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredAgents.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredAgents, currentPage, itemsPerPage]);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
    },
    []
  );

  const createNotification = useCallback(async () => {
    try {
      await axios.post("/superadmin/notification", {
        message,
        office_ids: selectedItems,
        recipient_type: "office",
      });
      setModalMessage(t("NotificationAddedSuccessfully"));
      setIsSuccess(true);
      setModalOpen(true);
    } catch (error) {
      console.error("Error creating notification:", error);
      setModalMessage(t("errorCreatingNotification"));
      setIsSuccess(false);
      setModalOpen(true);
    }
  }, [message, selectedItems, t]);

  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const formatArabicDate = useCallback(
    (dateString: string | number | Date) => {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("ar-EG", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    },
    []
  );

  const sortedNotifications = useMemo(() => {
    return [...notification].sort(
      (a, b) =>
        new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
    );
  }, [notification]);


  return (
    <Box display="flex" p={2} height="100vh" bgcolor="#fff">
      {/* Items Section */}
      <Box width="50%" p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={25} fontWeight={700} color="#11142d">
            {t("notifications")}
          </Typography>
        </Box>

        <SearchBar
          placeholder={t("search_placeholder")}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
        />

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow style={{ background: "rgba(220, 236, 255, 0.30)" }}>
                <TableCell></TableCell>
                <TableCell>{t("office_name")}</TableCell>
                <TableCell>{t("manager")}</TableCell>
                <TableCell>
                  <Checkbox
                    color="info"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAgents.map((agent) => (
                <AgentRow
                  key={agent.ID}
                  agent={agent}
                  isSelected={selectedItems.includes(agent.ID)}
                  onSelect={handleSelectItem}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(filteredAgents.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="standard"
            renderItem={(item) => (
              <CustomPaginationItem
                components={{
                  previous: () => (
                    <img
                      src="/keyboard_double_arrow_right.svg"
                      alt="Previous"
                      style={{ width: "24px", height: "24px" }}
                    />
                  ),
                  next: () => (
                    <img
                      src="/keyboard_double_arrow_left.svg"
                      alt="Next"
                      style={{ width: "24px", height: "24px" }}
                    />
                  ),
                }}
                {...item}
              />
            )}
          />
        </Box>
      </Box>

      {/* Notifications Section */}
      <Box width="50%" borderRight="1px solid #e0e0e0" p={2}>
        <Box
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          {sortedNotifications.map((note, index) => (
            <Box
              key={index}
              p={2}
              mb={2}
              border="1px solid #e0e0e0"
              borderRadius="8px"
              bgcolor="rgba(141, 167, 200, 0.3)"
            >
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="body1" fontWeight={600} color="#255186">
                  {note.title}
                </Typography>
                <Typography variant="caption" display="block">
                  {formatArabicDate(note.CreatedAt)}
                </Typography>
              </Box>
              <Typography color="#255186" variant="body2">
                {note.Message}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <IconButton onClick={createNotification}>
            <img
              src="/send.svg"
              alt="Send"
              style={{ width: "40px", height: "40px" }}
            />
          </IconButton>
          <TextField
            fullWidth
            placeholder="اكتب هنا..."
            variant="outlined"
            size="small"
            multiline
            rows={2}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
      </Box>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <img
            src={isSuccess ? "/letsiconscheckfill.svg" : "/mdi--error-2.svg"}
            alt={isSuccess ? "Success" : "Error"}
            style={{ width: "59px", height: "59px" }}
          />
          <Typography id="success-modal-title" variant="h4" component="h2" m={5}>
            {modalMessage}
          </Typography>
          <CustomButton
            type="submit"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Item;
