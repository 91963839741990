import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  InputBase,
  Paper,
  Button,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "axiosConfig";
import { useNavigate } from "react-router-dom";

const AdminList = (id: any) => {
  const [admins, setAdmins] = useState<
    { id: number; username?: string; email?: string }[]
  >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteAdminId, setDeleteAdminId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const adminId = id?.id;
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        setLoading(true);
        if (!adminId) {
          console.error("Admin ID is missing");
          return;
        }
        const response = await axiosInstance.get(
          `/superadmin/get_admins/${adminId}`
        );
        setAdmins(response.data.admins || []);
      } catch (error) {
        console.error("Error fetching admins:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdmins();
  }, [id]);

  const filteredAdmins = useMemo(() => {
    return admins.filter((admin) => {
      const name = admin.username?.toLowerCase() || "";
      const query = searchQuery.trim().toLowerCase();
      return name.includes(query);
    });
  }, [admins, searchQuery]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleAddAdmin = () => {
    navigate(`/superadmin/add_admin/create/${adminId}`);
  };

  const handleDeleteAdmin = async () => {
    if (!deleteAdminId) return;

    try {
      await axiosInstance.delete(`/superadmin/delete_admins/${deleteAdminId}`);
      setAdmins((prev) => prev.filter((admin) => admin.id !== deleteAdminId));
      setDeleteAdminId(null);
      setOpenDeleteModal(false);
    } catch (error) {
      console.error("Error deleting admin:", error);
      alert("Failed to delete admin. Please try again.");
    }
  };

  const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E7FF",
    borderRadius: "8px",
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#F8FAFF",
    width: "50%",
  }));

  const CustomInputBase = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    "& input::placeholder": {
      textAlign: "right",
    },
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#2E6CB6",
    color: "#fff",
    gap: theme.spacing(1),
    padding: theme.spacing(1, 3),
    textTransform: "none",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#1A4C96",
    },
  }));

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography style={{ color: "#111923" }} variant="h5" fontWeight="700">
           مديري المكتب
        </Typography>
        <StyledButton
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddAdmin}
        >
          إضافة مدير
        </StyledButton>
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <SearchBox>
          <IconButton sx={{ color: "#8DA7C8" }}>
            <SearchIcon />
          </IconButton>
          <CustomInputBase
            placeholder="البحث..."
            value={searchQuery}
            onChange={handleSearchChange}
            inputRef={searchInputRef}
          />
        </SearchBox>
      </Box>

      <TableContainer component={Paper}>
        {loading ? (
          <Typography sx={{ p: 2, textAlign: "center" }}>
            Loading admins...
          </Typography>
        ) : filteredAdmins.length > 0 ? (
          filteredAdmins.map((admin) => (
            <Box
              key={admin?.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              borderBottom="1px solid #ddd"
            >
              <Typography>{admin?.username}</Typography>
              <IconButton
                sx={{ color: "#8DA7C8" }}
                onClick={() => {
                  setDeleteAdminId(admin.id);
                  setOpenDeleteModal(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography sx={{ p: 2, textAlign: "center" }}>
            No admins found.
          </Typography>
        )}
      </TableContainer>

      {/* Delete Confirmation Modal */}
      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>حذف المدير</DialogTitle>
        <DialogContent>
          <DialogContentText>هل انت متأكد من حذف هذا المدير؟</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>إلغاء</Button>
          <Button onClick={handleDeleteAdmin} autoFocus>
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
// oldGold
export default AdminList;
