import { Box, Modal, Typography } from "@mui/material";
import { useGetIdentity } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { CustomButton } from "components";
import RequestForm from "components/requests/RequestForm";
import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axiosConfig";

const CreateRequest = () => {
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: true,
  });
  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(false);
  const [missingDataModal, setMissingDataModal] = useState(false);
  const location = useLocation();

  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem("dataUser");
    return savedData ? JSON.parse(savedData) : location.state?.user;
  });

  const [dataUser, setDataUser] = useState(() => {
    const savedData = localStorage.getItem("data");
    return savedData ? JSON.parse(savedData) : location.state?.agent;
  });

  useEffect(() => {
    if (location.state?.user !== undefined) {
      setData(location.state.user);
      localStorage.setItem("dataUser", JSON.stringify(location.state.user));
    }
    if (location.state?.agent !== undefined) {
      setDataUser(location.state.agent);
      localStorage.setItem("data", JSON.stringify(location.state.agent));
    }
  }, [location]);

  const onFinishHandler = async (formData: FieldValues) => {
    if (!data || !dataUser) {
      setMissingDataModal(true);
      return;
    }
  
    // Create a new FormData instance
    const payload = new FormData();
  
    // Append simple text fields
    payload.append("request_type", formData.request_type || "Legal Advice");
    payload.append("description", formData.description || "Need advice on contract law");
    payload.append("user_id", data?.id);
    payload.append("case_type", formData.case_type || "Contract");
    payload.append("location", formData.location || "Unknown");
    payload.append("plaintiff_name", formData.plaintiff_name || "Unknown");
    payload.append("defendant_name", formData.defendant_name || "Unknown");
    payload.append("national_address", formData.national_address || "Not Provided");
  
    if (formData.amount) {
      payload.append("amount", formData.amount);
    }
    if (formData.notes) {
      payload.append("notes", formData.notes);
    }
  
    // Append the file if provided.
    // Note: if your file input allows multiple files, formData.uploadedFile might be an array.
    if (formData.uploadedFile) {
      const file = formData.uploadedFile; // get the first file
      payload.append("file", file); // key is "file"
      payload.append("document_type", "request");
      payload.append("filename", file);
    }
  
    // Append complex objects as JSON strings
    payload.append("user", JSON.stringify(data));
    payload.append("lawyer", JSON.stringify(dataUser));
    console.log("🚀 ~ onFinishHandler ~ payload:", payload)
  
    try {
      // Remove the Content-Type header to let axios set it automatically for multipart/form-data
      const response = await axios.post("/admin/create_request", payload);
  
      console.log("API Response:", response);
  
      setSuccessMessage(true);
      localStorage.removeItem("requestForm");
    } catch (error) {
      console.log("API Request Error:", error);
    }
  };
  

  const handleClose = () => {
    setSuccessMessage(false);
    navigate("/Requests");
  };

  return (
    <div>
      <RequestForm
        type="Create"
        register={register}
        onFinish={onFinish}
        formLoading={formLoading}
        handleSubmit={handleSubmit}
        agent={user}
        onFinishHandler={onFinishHandler}
        handleImageChange={function (file: any): void {}}
        propertyImage={{
          name: "",
          url: "",
        }}
        control={undefined}
        agentData={undefined}
      />

      {/* Success Message Modal */}
      <Modal
        open={successMessage}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}>
          <img
            src="/letsiconscheckfill.svg"
            alt="Next"
            style={{ width: "59px", height: "59px" }}
          />
          <Typography
            id="success-modal-title"
            variant="h4"
            component="h2"
            m={5}>
            {t("RequestAddedSuccessfully")}
          </Typography>
          <CustomButton
            type="submit"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
          />
        </Box>
      </Modal>

      {/* Missing Data Modal */}
      <Modal
        open={missingDataModal}
        onClose={() => setMissingDataModal(false)}
        aria-labelledby="missing-data-modal-title"
        aria-describedby="missing-data-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}>
          <Typography
            id="missing-data-modal-title"
            variant="h4"
            component="h2"
            m={5}>
            {t("Missing Information")}
          </Typography>
          <Typography
            id="missing-data-modal-description"
            variant="body1"
            component="p"
            mb={3}>
            {data
              ? t("Please select a lawyer before submitting.")
              : t("Please select a user before submitting.")}
          </Typography>
          <CustomButton
            type="button"
            title={t("Back")}
            backgroundColor="#D32F2F"
            color="#fcfcfc"
            handleClick={() => setMissingDataModal(false)}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default CreateRequest;
